//Modules
import { useRef } from 'react'
import { formatDate } from '@fullcalendar/core'
import { toast } from 'react-toastify'

const FormEventClickWeek = ({ selectedData, handleWeekSubmitReservation, formData, onChangeFormData, handleCancelReservation }) => {
    const { description, obs } = formData
    const descriptionRef = useRef(null)

    const onSubmitData = (e) => {
        e.preventDefault()

        if (!descriptionRef.current.value){
            toast.warning('Por favor preencha o motivo.')
        }else{
            handleWeekSubmitReservation()
        }
    }

    return (
        <section>
            <div className="fixed top-0 left-0 w-screen h-screen z-[999] bg-slate-600 opacity-60"></div>
            <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] md:w-3/4 lg:w-1/4 bg-white p-5 rounded-lg shadow-md z-[9999]">
            <h2 className='text-slate-600 mb-5 text-2xl text-center'>Reserva para {formatDate(selectedData.startStr, {day:'2-digit', month: '2-digit', year:'numeric', timeZone: 'Europe/lisbon', locale: 'pt'})}</h2>
            <h4 className='text-slate-600 mb-5 text-xl text-center'>Das {formatDate(selectedData.startStr, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})} às {formatDate(selectedData.endStr, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</h4>
                <form onSubmit={onSubmitData}>
                    <div className="flex gap-3 items-center">
                        <label htmlFor="description" className='mb-2'>Motivo:</label>
                        <input type='text' id='description' name='description' ref={descriptionRef} value={description} placeholder='Motivo da reserva' onChange={onChangeFormData} className='w-full p-2 mb-3 border border-solid border-gray-400 rounded-md' />
                    </div>
                    <div className="flex flex-col pt-5">
                        <label htmlFor="obs" className='mb-2'>Observações:</label>
                        <textarea rows="10" cols="100" id='obs' name='obs' value={obs} placeholder='Indique as necessidades da reserva. (ex.:Copos e água, videoconferência, gravar o evento, etc...)' onChange={onChangeFormData} className='w-full p-2 mb-3 border border-solid border-gray-400 rounded-md' ></textarea>
                    </div>
                    <br />
                    <div className='flex flex-col md:flex-row mx-5 mt-6 justify-center gap-6'>                            
                        <button className='bg-esact-600 text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer hover:bg-esact-900' type="submit">Guardar</button>
                        <button className='bg-esact-600 text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer hover:bg-esact-900' type="button" onClick={handleCancelReservation}>Cancelar</button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default FormEventClickWeek
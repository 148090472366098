//Modules
import { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { SiGoogleclassroom } from 'react-icons/si'
import { FiCheckCircle } from 'react-icons/fi'
import { TiDeleteOutline } from 'react-icons/ti'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'

//Components
import Spinner from '../components/Spinner'
import ListManagersSpace from '../components/managers/ListManagersSpace'
import FormAddManager from '../components/managers/FormAddManager'

//Utils
import { getSpace, spaceReset } from '../features/spaces/spaceSlice'
import managerService from '../features/managers/managerService'
import { useLogout, isSessionActive } from '../utils/UtilFunctions'
import { roleTypes, spaceTypes } from '../utils/UtilTypes'

const SpaceSingle = () => {
    let [isOpen, setIsOpen] = useState(false)
    const [spaceManagers, setSpaceManagers] = useState([])

    const { id: spaceId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user } = useSelector((state) => state.auth)
    const { space, isError: spaceError, message: messageSpace } = useSelector((state) => state.space)
    
    const onLogout = useLogout()
    const active = isSessionActive()

    const getManagersData = async () => {
        const spaceManagersTemp = await managerService.getSpacesManagers(spaceId, user)
        setSpaceManagers(spaceManagersTemp)
    }

    function closeModal() {
        setIsOpen(false)
    }
    
    function openModal() {
      setIsOpen(true)
    }

    useEffect(() => {     
      if(!active.sessionState){
        onLogout.out()
        toast.warning(active.message)
      }else{        
        if (!space){
          dispatch(getSpace(spaceId))
        }
        getManagersData()
      }

      if (spaceError) {
        navigate('/')
        toast.warning(messageSpace)
      }

      return () => {
          dispatch(spaceReset())
      }
    }, []) 

    if (!space || !user) {
        return <Spinner />
    }


  return (
    <section className='px-5 md:px-0'>
        <div className="mt-5">
          <button
            type="button"
            onClick={() => {navigate('/spaces')}}
            className="rounded-md bg-esact-600 px-4 py-2 text-md font-medium text-white focus:outline-none"
          >
            Voltar
          </button>
        </div>
        <div className='mt-5 w-full rounded-xl shadow-custom1 bg-white p-6 flex justify-between'>
            <div className='flex gap-5 items-center'>
                <SiGoogleclassroom className='text-[4rem] text-gray-800' />
                <div className='flex flex-col'>
                    <h2 className='text-md md:text-xl text-gray-700'>{space.name}</h2>
                    <p className='text-sm md:text-md text-gray-500'>Capacidade: {space.capacity === 0 ? "n/d" : space.capacity}</p>
                    <p className='text-sm md:text-md text-gray-500'>Acesso:
                      {space.role.map((role, index) => (
                        <span key={index} className='text-gray-500'> {roleTypes[role]}{index !== space.role.length-1 && ', '}</span>
                      ))}
                    </p>
                    <p className='text-sm md:text-md text-gray-500'>Tipo:
                      {space.type.map((type, index) => (
                        <span key={index} className='text-gray-500'> {spaceTypes[type]}{index !== space.type.length-1 && ', '}</span>
                      ))}
                    </p>
                </div>
            </div>
            <div className='flex flex-col items-end gap-2'>
                <p className='text-md md:text-lg font-bold text-gray-600 flex gap-3 items-center'><span>Visibilidade </span>{space.isVisible ? <FiCheckCircle className='text-lg text-esact-900' /> : <TiDeleteOutline className='text-2xl text-rejected' />}</p>
                <p className='text-md md:text-lg font-bold text-gray-600 flex gap-3 items-center'><span>Aulas </span>{space.hasClasses ? <FiCheckCircle className='text-lg text-esact-900' /> : <TiDeleteOutline className='text-2xl text-rejected' />}</p>
            </div>
        </div>

        <ListManagersSpace managers={spaceManagers} spaceId={spaceId} setSpaceManagers={setSpaceManagers} />

        <div className="mt-5">
          <button
            type="button"
            onClick={openModal}
            className="rounded-md bg-esact-600 px-4 py-2 text-md font-medium text-white focus:outline-none"
          >
            Adicionar
          </button>
        </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="relative text-lg font-medium leading-6 text-gray-900 uppercase text-center mb-10"
                  >
                    Adicionar Gestor
                    <span onClick={closeModal} className='absolute text-2xl cursor-pointer text-gray-500 top-[-15px] right-[-15px]'><AiOutlineCloseCircle /></span>
                  </Dialog.Title>                  
                  <FormAddManager space_id={space._id} closeModal={closeModal} managers={spaceManagers} setSpaceManagers={setSpaceManagers} />                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </section>
  )
}

export default SpaceSingle
//Modules
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip';
import { FaSquare } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { formatDate } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import { useConfirm } from 'react-confirm-window'


//Utils
import { getSpace, spaceReset } from '../features/spaces/spaceSlice'
import { setReservation, deleteReservation, updateReservation, changeUserReservationState } from '../features/reservations/reservationSlice'
import { getUser, updateUser } from '../features/auth/authSlice'
import authService from '../features/auth/authService'
import reservationService from '../features/reservations/reservationService'
import managerService from '../features/managers/managerService'
import emailService from '../features/email/emailService'
import { useWindowWidthContext } from '../utils/UtilContext'
import { handleEventClick, 
    handleMonthDayClick, 
    handleMonthSubmitReservation, 
    handleCancelReservation, 
    onChangeFormData, 
    handleWeekSubmitReservation,
    handleSubmitUpdateReservation,
    handleSelect 
} from '../utils/UtilSpace'
import { useLogout, isSessionActive } from '../utils/UtilFunctions'

//Components
import FormEventClickWeek from '../components/spaces/FormEventClickWeek';
import FormEventClickMonth from '../components/spaces/FormEventClickMonth';
import FormEventClick from '../components/spaces/FormEventClick';
import FormShowTutorial from '../components/spaces/FormShowTutorial';
import Spinner from '../components/Spinner'
import animation from '../assets/AnimationCalendar.gif'


function Space() {

    //VARIABLES
    const { REACT_APP_BASE_URL } = process.env

    const [loadedEvents, setLoadedEvents] = useState([])
    const [spaceManagers, setSpaceManagers] = useState([])
    const [isUserManager, setIsUserManager] = useState(false)
    const [view, setView] = useState('timeGridWeek');
    const [isLoadingData, setIsLoadingData] = useState(true)

    const [showTutorial, setShowTutorial] = useState(false)

    const [showFormMonthView, setShowFormMonthView] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [startTime, setStartTime] = useState('08:00')
    const [endTime, setEndTime] = useState('08:00')
    const [selectedDate, setSelectedDate] = useState('')
    
    
    const [showFormWeekView, setShowFormWeekView] = useState(false)
    const [formData, setFormData] = useState({
        description: '',
        obs: ''
    })
    
    const [selectedData, setSelectedData] = useState({})

    const [showFormClickEvent, setShowFormClickEvent] = useState(false)

    const {id: spaceId, date = false} = useParams()
    const calendarRef = useRef(null)
    const tutorialRef = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const confirm = useConfirm()

    const { windowWidth } = useWindowWidthContext()

    const calendarApi = calendarRef.current

    let initialDate
    let isManager = []
    
    const { user } = useSelector((state) => state.auth)
    const { space, isError: spaceError, message: messageSpace } = useSelector((state) => state.space)
    const { userReservations } = useSelector((state) => state.reservation)
    
    !date ? initialDate = `${new Date().toLocaleString('pt-PT',{year:"numeric"})}-${new Date().toLocaleString('pt-PT',{month:"2-digit"})}-${new Date().toLocaleString('pt-PT',{day:"2-digit"})}` 
        : initialDate=date

    const onLogout = useLogout()
    const active = isSessionActive()
    
    //FUNCTIONS

    const getManagersData = async () => {
        const spaceManagersTemp = await managerService.getSpacesManagers(spaceId, user)
        setSpaceManagers(spaceManagersTemp)
        isManager = spaceManagersTemp.filter((manager) => manager._id === user._id)
        if (isManager.length !== 0){
            setIsUserManager(true)            
        }        
        setIsLoadingData(false)
    }

    useEffect(() => {
        if(!active.sessionState){
            onLogout.out()
            toast.warning(active.message)
        }else{            
            if (!space) {
                const resSpace = dispatch(getSpace(spaceId))
                resSpace.then(async (data) => {
                    if (data.error) {
                        toast.error(data.payload)      
                        dispatch(spaceReset())
                        navigate('/')
                    }
                })
            }
            user && setShowTutorial(user.tutorial)
            windowWidth <= 1024 ? setView('timeGridDay') : setView('timeGridWeek')
    
            getManagersData()
        }  

        return () => {
            dispatch(spaceReset())
        }
    }, []) 

    useEffect(() => {
        if (windowWidth <= 1024){
            calendarApi?.getApi().changeView('timeGridDay')
        }else{
            calendarApi?.getApi().changeView('timeGridWeek')
        }
    },[windowWidth])

    //Funçao que guarda a reserva na BD e mostra no calendário
    const submitReservation = (reservationData) => {
        const result = dispatch(setReservation(reservationData))
        result.then(async (data) => {
            if (data.error){
                toast.error(data.payload)                
            }else{
                const newEvent = {
                    id: data.payload.reservation._id,
                    title: user.name,
                    start: data.payload.reservation.begin,
                    end: data.payload.reservation.end,
                    extendedProps: {
                        state: data.payload.reservation.state,
                        user: user._id,
                        description: data.payload.reservation.description,
                        created: data.payload.reservation.createdAt,
                        edited: data.payload.reservation.updatedAt,  
                        isClass: false,
                        obs: data.payload.reservation.obs
                    },
                    backgroundColor: "#ff6f3c",
                    borderColor: "#ff6f3c"
                }
                const newUserReservations = [...userReservations, data.payload.reservation]
                dispatch(changeUserReservationState(newUserReservations))
                const newLoadedEvents = [...loadedEvents, newEvent]                    
                setLoadedEvents(newLoadedEvents)
                
                if(!isUserManager && spaceManagers.length !== 0){                              
                    let managersEmails = ''
                    spaceManagers.map((manager) => managersEmails = `${managersEmails}${manager.email},`)
                    emailService.sendEmail({
                        email: managersEmails,
                        subject: "Reserva de Salas",
                        message: "Caro(a) gestor(a),<br /> foi criada uma <a href='"+REACT_APP_BASE_URL+"/space/"+space._id+"/"+newEvent.start.slice(0,10)+"'>reserva</a> na sala <b>"+ space.name +"</b>, no dia <b>"+ formatDate(newEvent.start, {year:'numeric', month: '2-digit', day: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                +"</b> das <b>"+ formatDate(newEvent.start, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                +"</b> às <b>"+ formatDate(newEvent.end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                +"</b>, pelo utilizador "+ user.name +"."
                    },user)
                }
                
                toast.success(data.payload.message)
            }
        }) 
    }

    //Função que apaga a reserva
    const deleteEvent = async (clickInfo) => {          
        const choice = await confirm({
            title: "Tem a certeza que deseja apagar a reserva?",
            closeButtonLable: "Não",
            confirmButtonLable: "Sim",
        });
           
        if (choice){
            const result = dispatch(deleteReservation(clickInfo.event._def.publicId))
            result.then((data) => {                      
                if (data.error){
                    toast.error(data.payload)
                }else{                    
                    const newUserReservations = userReservations.filter((reservation) => reservation._id !== clickInfo.event._def.publicId)       
                    dispatch(changeUserReservationState(newUserReservations))
                    const newLoadedEvents = loadedEvents.filter((event) => event.id !== clickInfo.event._def.publicId)
                    setLoadedEvents(newLoadedEvents)
    
                    if(user._id !== clickInfo.event._def.extendedProps.user){                
                        const getUserResult = dispatch(getUser(clickInfo.event._def.extendedProps.user))                        
                        getUserResult.then((data) => {                            
                            let message = "Caro(a) " + data.payload.name + ",<br /> a sua reserva do dia <b>"+ formatDate(clickInfo.event._instance.range.start, {year:'numeric', month: '2-digit', day: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                        +"</b> das <b>"+ formatDate(clickInfo.event._instance.range.start, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                        +"</b> às <b>"+ formatDate(clickInfo.event._instance.range.end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                        +"</b> na sala <b>"+ space.name +"</b>, foi apagada."
                                        +"<br /><br /> Caso tenha alguma dúvida contacte, por favor: <br /><br />"
                            spaceManagers.map((manager) => 
                                message = message+manager.name+"<br />"
                                +"<b>Email:</b> "+manager.email+"<br />"
                                +"<b>Ext.:</b> "+manager.phone+"<br />"
                                +"<b>Gab.:</b> "+manager.office+"<br /><br />"
                            ) 
                            emailService.sendEmail({
                                email: data.payload.email,
                                subject: "Reserva de Salas",
                                message: message                                       
                            },user)
                        })
                    }
                    toast.success(data.payload.message)
                    setFormData({description: '', obs: ''})
                    setShowFormMonthView(false)
                    setShowFormWeekView(false)
                    setShowFormClickEvent(false)
                }
            })                    
        }
    }

    //Funçao que gere a alteração de estado da reserva Pendente/Aprovada
    const changeState = (clickInfo) => {
        let newState   
        clickInfo.event._def.extendedProps.state === 1 ? newState = {state: "aprovada", code: 2} : newState = {state: "cancelada", code: 1}
        const dataUpdated = {
            id: clickInfo.event._def.publicId,
            state: newState.code,            
        }
        const updateResult = dispatch(updateReservation(dataUpdated))
        updateResult.then((res) => {
            if (res.error){
                toast.error(res.payload)
            }else{ 
                if (user._id === clickInfo.event._def.extendedProps.user){                    
                    const newUserReservations = userReservations.filter((reservation) => reservation._id !== clickInfo.event._def.publicId)
                    newUserReservations.push(res.payload.updatedReservation)
                    dispatch(changeUserReservationState(newUserReservations))         
                }

                const eventChanged = {
                    backgroundColor: res.payload.updatedReservation.state === 0 ? "#dc2f2f" : (res.payload.updatedReservation.state === 1 ? "#ff6f3c" : "#42b883"),
                    borderColor: res.payload.updatedReservation.state === 0 ? "#dc2f2f" : (res.payload.updatedReservation.state === 1 ? "#ff6f3c" : "#42b883"),
                    editable: true,
                    end: clickInfo.event._instance.range.end,
                    extendedProps: {
                        state: res.payload.updatedReservation.state,
                        user: clickInfo.event._def.extendedProps.user,
                        description: clickInfo.event._def.extendedProps.description,
                        created: clickInfo.event._def.extendedProps.created,
                        edited: res.payload.updatedReservation.updatedAt,  
                        isClass: false,
                        obs: clickInfo.event._def.extendedProps.obs
                    },
                    id: clickInfo.event._def.publicId,
                    start: clickInfo.event._instance.range.start,
                    title: clickInfo.event._def.title
                }   
                
                setLoadedEvents((prevSate) => {
                    const newLoadedEvents = prevSate.filter((event) => event.id !== clickInfo.event._def.publicId)                    
                    newLoadedEvents.push(eventChanged)
                    return newLoadedEvents
                })
                
                if(user._id !== clickInfo.event._def.extendedProps.user){
                    const getUserResult = dispatch(getUser(clickInfo.event._def.extendedProps.user))
                    getUserResult.then((data) => {   
                        let message = "Caro(a) " + data.payload.name + ",<br />a sua <a href='"+REACT_APP_BASE_URL+"/space/"+space._id+"/"+res.payload.updatedReservation.begin.slice(0,10)+"'>reserva</a> do dia <b>"+ formatDate(clickInfo.event._instance.range.start, {year:'numeric', month: '2-digit', day: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> das <b>"+ formatDate(clickInfo.event._instance.range.start, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> às <b>"+ formatDate(clickInfo.event._instance.range.end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> na sala <b>"+ space.name +"</b>, foi "+ newState.state +"."
                                    +"<br /><br /> Caso tenha alguma dúvida contacte, por favor:<br /><br />"    
                        spaceManagers.map((manager) => 
                            message = message+manager.name+"<br />"
                            +"<b>Email:</b> "+manager.email+"<br />"
                            +"<b>Ext.:</b> "+manager.phone+"<br />"
                            +"<b>Gab.:</b> "+manager.office+"<br /><br />"
                        )             
                        emailService.sendEmail({
                            email: data.payload.email,
                            subject: "Reserva de Salas",
                            message: message
                        },user)
                    })   
                }
                toast.success(res.payload.message)
                setFormData({description: '', obs: ''})
                setShowFormMonthView(false)
                setShowFormWeekView(false)
                setShowFormClickEvent(false)
            }
        })
    }

    //Função com o template da reserva
    const renderEventContent = (eventInfo) => {
        const today = new Date()
        return(
            <>            
            <div data-tooltip-id={eventInfo.event._def.publicId} className='flex flex-col justify-between p-2 h-full overflow-hidden'>                
                {eventInfo.view.type !== "dayGridMonth" ?
                <>                
                    <div>
                        <p><small>{eventInfo.timeText}</small></p>
                        <p><strong>{eventInfo.event.title}</strong></p>
                        <p>{eventInfo.event._def.extendedProps?.description}</p>
                    </div>
                <div className='flex justify-between items-end'>   
                    {(user.role.includes("root") || user.role.includes("admin") || isUserManager || eventInfo.event._def.extendedProps.user === user._id) &&
                    <div>  
                        {!eventInfo.event._def.extendedProps.isClass ?
                            <>                      
                                <p><small><strong>Criada em:</strong> {formatDate(eventInfo.event._def.extendedProps.created, {day:'2-digit', month:'2-digit', year:'2-digit', hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</small></p>                        
                                <p><small><strong>Editada em:</strong> {formatDate(eventInfo.event._def.extendedProps.edited, {day:'2-digit', month:'2-digit', year:'2-digit', hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</small></p>
                            </>
                            : <span></span>
                        }    
                    </div>}  
                    {((user.role.includes("root") && eventInfo.event._def.extendedProps.state !== 3) || (eventInfo.event._instance.range.start > today && (isUserManager || eventInfo.event._def.extendedProps.user === user._id))) ? 
                        <FiEdit className='text-2xl cursor-pointer'/>  :           
                    <span></span>}
                </div>
                </>:
                <div>
                    <p><small>{eventInfo.timeText}</small> - <strong style={{color: eventInfo.backgroundColor}}>{eventInfo.event.title}</strong></p>
                </div>}
            </div>
            {(((eventInfo.event._instance.range.end - eventInfo.event._instance.range.start <= 7200000) || (eventInfo.view.type === 'dayGridMonth')) && (user.role.includes("root") || isUserManager || user._id === eventInfo.event._def.extendedProps.user)) &&
            <Tooltip id={eventInfo.event._def.publicId}>
                <div className='flex flex-col gap-2'>
                    <div>
                        <p><small>{eventInfo.timeText}</small></p>
                        <p><strong>{eventInfo.event.title}</strong></p>
                        <p>{eventInfo.event._def.extendedProps?.description}</p>
                    </div>
                    {(user.role.includes("root") || user.role.includes("admin") || isUserManager || eventInfo.event._def.extendedProps.user === user._id) &&
                    <div>                        
                        {!eventInfo.event._def.extendedProps.isClass ?
                            <>                      
                                <p><small><strong>Criada em:</strong> {formatDate(eventInfo.event._def.extendedProps.created, {day:'2-digit', month:'2-digit', year:'2-digit', hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</small></p>                        
                                <p><small><strong>Editada em:</strong> {formatDate(eventInfo.event._def.extendedProps.edited, {day:'2-digit', month:'2-digit', year:'2-digit', hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</small></p>
                            </>
                            : <span></span>
                        }                       
                    </div>} 
                </div> 
            </Tooltip>}
            </>
        )
    }

    //Função que faz a alteração de uma reserva
    const updateEvent = (reservationData, info) => {
        const today = new Date()
        let eventChanged
        const updateResult = dispatch(updateReservation(reservationData))
        updateResult.then((res) => {
            if (res.error){
                toast.error(res.payload)
            }else{
                const newUserReservations = userReservations.filter((reservation) => reservation._id !== info.event._def.publicId)
                newUserReservations.push(res.payload.updatedReservation)
                dispatch(changeUserReservationState(newUserReservations))

                eventChanged = {
                    backgroundColor: info.event._def.ui.backgroundColor,
                    borderColor: info.event._def.ui.borderColor,
                    editable: user.role.includes("root") ? true : (new Date(res.payload.updatedReservation.begin)>today ? (isUserManager ? true : (user._id === res.payload.updatedReservation.user_id ? (res.payload.updatedReservation.state === 1 ? true : false) : false)) : false),
                    end: info.event._instance.range.end,
                    extendedProps: {
                        state: info.event._def.extendedProps.state,
                        user: info.event._def.extendedProps.user,
                        description: res.payload.updatedReservation.description,
                        created: info.event._def.extendedProps.created,
                        edited: res.payload.updatedReservation.updatedAt,  
                        isClass: false,
                        obs: res.payload.updatedReservation.obs
                    },
                    id: res.payload.updatedReservation._id,
                    start: info.event._instance.range.start,
                    title: info.event._def.title
                }

                setLoadedEvents((prevSate) => {
                    const newLoadedEvents = prevSate.filter((event) => event.id !== info.event._def.publicId)
                    newLoadedEvents.push(eventChanged)
                    return newLoadedEvents
                })

                if(!isUserManager && spaceManagers.length !== 0 && !user.role.includes("root")){          
                    let managersEmails = ''
                    spaceManagers.map((manager) => managersEmails = `${managersEmails}${manager.email},`)
                    emailService.sendEmail({
                        email: managersEmails,
                        subject: "Reserva de Salas",
                        message: "Caro(a) gestor(a),<br /> a <a href='"+REACT_APP_BASE_URL+"/space/"+space._id+"/"+res.payload.updatedReservation.begin.slice(0,10)+"'>reserva</a> na sala <b>"+ space.name +"</b>, no dia <b>"+ formatDate(eventChanged.start, {year:'numeric', month: '2-digit', day: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                +"</b> das <b>"+ formatDate(eventChanged.start, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                +"</b> às <b>"+ formatDate(eventChanged.end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                +"</b>, foi alterada pelo utilizador "+ user.name +"."
                    },user)
                }else if(isUserManager){
                    const getUserResult = dispatch(getUser(info.event._def.extendedProps.user))
                    getUserResult.then((data) => {  
                        let message = "Caro(a) " + data.payload.name + ", a sua <a href='"+REACT_APP_BASE_URL+"/space/"+space._id+"/"+res.payload.updatedReservation.begin.slice(0,10)+"'>reserva</a> do dia <b>"+ formatDate(info.oldEvent._instance.range.start, {year:'numeric', month: '2-digit', day: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> das <b>"+ formatDate(info.oldEvent._instance.range.start, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> às <b>"+ formatDate(info.oldEvent._instance.range.end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> na sala <b>"+ space.name +"</b>, foi alterada."
                                    +"<br /><br /> Caso tenha alguma dúvida contacte, por favor:<br /><br />"  
                        spaceManagers.map((manager) => 
                            message = message+manager.name+"<br />"
                            +"<b>Email:</b> "+manager.email+"<br />"
                            +"<b>Ext.:</b> "+manager.phone+"<br />"
                            +"<b>Gab.:</b> "+manager.office+"<br /><br />"
                        )             
                        emailService.sendEmail({
                            email: data.payload.email,
                            subject: "Reserva de Salas",
                            message: message
                        },user)
                    })
                }

                toast.success(res.payload.message)
            }
        })

        return eventChanged
    }

    //Função que gere a alteração de data da reserva
    const updateChangeDatesEvent = (args) => {    
        const today = new Date()
        const dataUpdated = {
            id: args.event._def.publicId,
            begin: args.event._instance.range.start,
            end: args.event._instance.range.end,
            state: args.event._def.extendedProps.state,
            description: args.event._def.extendedProps.description
        }        
        const updateResult = dispatch(updateReservation(dataUpdated))
        updateResult.then((res) => {
            if (res.error){
                toast.error(res.payload)
            }else{
                const newUserReservations = userReservations.filter((reservation) => reservation._id !== args.event._def.publicId)
                newUserReservations.push(res.payload.updatedReservation)
                dispatch(changeUserReservationState(newUserReservations))

                const eventChanged = {
                    backgroundColor: args.event._def.ui.backgroundColor,
                    borderColor: args.event._def.ui.borderColor,
                    editable: user.role.includes("root") ? true : (new Date(res.payload.updatedReservation.begin)>today ? (isUserManager ? true : (user._id === res.payload.updatedReservation.user_id ? (res.payload.updatedReservation.state === 1 ? true : false) : false)) : false),
                    end: args.event._instance.range.end,
                    extendedProps: {
                        state: args.event._def.extendedProps.state,
                        user: args.event._def.extendedProps.user,
                        description: args.event._def.extendedProps.description,
                        created: args.event._def.extendedProps.created,
                        edited: res.payload.updatedReservation.updatedAt,  
                        isClass: false,
                        obs: args.event._def.extendedProps.obs
                    },
                    id: args.event._def.publicId,
                    start: args.event._instance.range.start,
                    title: args.event._def.title
                }   
                
                setLoadedEvents((prevSate) => {
                    const newLoadedEvents = prevSate.filter((event) => event.id !== args.event._def.publicId)
                    newLoadedEvents.push(eventChanged)
                    return newLoadedEvents
                })

                if(user._id !== args.event._def.extendedProps.user){                
                    const getUserResult = dispatch(getUser(args.event._def.extendedProps.user))
                    getUserResult.then((data) => {  
                        let message = "Caro(a) " + data.payload.name + ", a sua <a href='"+REACT_APP_BASE_URL+"/space/"+space._id+"/"+res.payload.updatedReservation.begin.slice(0,10)+"'>reserva</a> do dia <b>"+ formatDate(args.oldEvent._instance.range.start, {year:'numeric', month: '2-digit', day: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> das <b>"+ formatDate(args.oldEvent._instance.range.start, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> às <b>"+ formatDate(args.oldEvent._instance.range.end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> na sala <b>"+ space.name +"</b>, foi alterada para o dia <b>"+ formatDate(args.event._instance.range.start, {year:'numeric', month: '2-digit', day: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> das <b>"+ formatDate(args.event._instance.range.start, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})
                                    +"</b> às <b>"+ formatDate(args.event._instance.range.end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'}) +"</b>."
                                    +"<br /><br /> Caso tenha alguma dúvida contacte, por favor:<br /><br />"  
                        spaceManagers.map((manager) => 
                            message = message+manager.name+"<br />"
                            +"<b>Email:</b> "+manager.email+"<br />"
                            +"<b>Ext.:</b> "+manager.phone+"<br />"
                            +"<b>Gab.:</b> "+manager.office+"<br /><br />"
                        )             
                        emailService.sendEmail({
                            email: data.payload.email,
                            subject: "Reserva de Salas",
                            message: message
                        },user)
                    })
                }
                toast.success(res.payload.message)
            }
        })
    } 

    //Função disparada quando se muda de datas 
    const changeDate = async (args) => {
        const today = new Date() 
        let processedClasses = []        
        if (space.hasClasses){            
            const spaceClassesReservation = await reservationService.getSpaceClassesReservations({codSpace: space.codSala, fim: args.end.toISOString().replace("T", " ").replace(".000Z", ""), inicio: args.start.toISOString().replace("T", " ").replace(".000Z", "")})            
            const classesMap = spaceClassesReservation.map(async (cls) => {   
                !cls.sumarios[0] && console.log(cls)
                const calendarClassesReservation = {
                    id: cls.aula.id,
                    title: (user.role.includes("root") || isUserManager) ? cls.docentes[0].nome : 'Aula',
                    start: cls.aula.inicio,
                    end: cls.aula.fim,
                    editable: false,
                    extendedProps: {
                        state: 3,
                        user: cls.aula.id,
                        description: (user.role.includes("root") || isUserManager) ? cls.disciplinas[0].nomeDisciplina : '',
                        created: new Date(),
                        updated: new Date(),
                        isClass: true,
                        obs: "obs"
                    },
                    backgroundColor: "#969696",
                    borderColor: "#969696",
                }
    
                return calendarClassesReservation
            })
            processedClasses = await Promise.all(classesMap)
        }

        const spaceReservations = await reservationService.getSpaceReservations({spaceId:spaceId, start: args.start, end: args.end},user)
        const reservMap = spaceReservations.map(async (reservation) => {
            let userReservation  
            if( user.role.includes("root") || isUserManager ){                
                userReservation = await authService.getUser(reservation.user_id,user)
            }
            const calendarReservation = {
                id: reservation._id,
                title: (user.role.includes("root") || isUserManager) ? userReservation.name : ( user._id === reservation.user_id ? user.name : "Reservado"),
                start: reservation.begin,
                end: reservation.end,
                editable: user.role.includes("root") ? true : (new Date(reservation.begin)>today ? (isUserManager ? true : (user._id === reservation.user_id ? (reservation.state === 1 ? true : false) : false)) : false),
                extendedProps: {
                    state: reservation.state,
                    user: reservation.user_id,
                    description: 'description' in reservation ? (user.role.includes("root") || isUserManager || user._id === reservation.user_id ? reservation.description : ' ') : ' ' ,
                    created: reservation.createdAt,
                    edited: reservation.updatedAt,
                    isClass: false,
                    obs: reservation.obs
                },
                backgroundColor: (user.role.includes("root") || isUserManager || user._id === reservation.user_id) ? (reservation.state === 0 ? "#dc2f2f" : (reservation.state === 1 ? "#ff6f3c" : "#42b883")) : "#0092ca",
                borderColor: (user.role.includes("root") || isUserManager || user._id === reservation.user_id) ? (reservation.state === 0 ? "#dc2f2f" : (reservation.state === 1 ? "#ff6f3c" : "#42b883")) : "#0092ca",
            }   
            
            return calendarReservation
        })
        const processedReservations = await Promise.all(reservMap)

        const inicialEvents = processedClasses.concat(processedReservations)
        setLoadedEvents(inicialEvents)
    }

    // Função para gerir a dispensa do tutorial
    const handleTutorial = () => {
        if (tutorialRef.current.checked) {
            let dataToUpdate = []
            dataToUpdate.push({tutorial: false}, user)
            const tutorial = dispatch(updateUser(dataToUpdate, user))
            tutorial.then((t) => {
                toast.success('Tutorial dispensado')
            })
        }
        setShowTutorial(false)
    }

    //BEGIN - Funções para gerir o clique numa reserva

    const mountedEvent = (args) => {

        if (user.role.includes("root") || isUserManager || user._id === args.event._def.extendedProps.user){            
            // console.log(args)
        }
    }    

    //END - Funções para gerir o hover numa reserva
    


    //LOADING
    if (!space || isLoadingData) {
        return <Spinner />
    }


    return (
        <>
        <section className='container my-[40px]'>
            <section>
                <h2 className='text-center text-xl md:text-2xl lg:text-3xl text-slate-600 font-bold mb-2'>{space.name}</h2>
                <h4 className='text-center text-lg md:text-xl text-slate-600 mb-2'>Capacidade: {space.capacity === 0 ? "n/d" : space.capacity}</h4>
            </section>
            <section className='px-5 sm:px-0'>                 
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    timeZone='Europe/Lisbon'
                    initialDate={initialDate}
                    headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: windowWidth >= 1024 ? 'timeGridWeek,dayGridMonth' : ''
                    }}
                    buttonText={{
                        today: 'hoje'
                    }}                    
                    locales={allLocales}
                    locale={'pt'}
                    initialView={view}
                    slotMinTime='08:00'
                    slotMaxTime='23:00'
                    businessHours = {{
                        daysOfWeek: [1,2,3,4,5,6],
                        startTime: '08:00',
                        endTime: '23:00'
                    }}
                    selectConstraint = "businessHours"                    
                    height={'auto'}
                    slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                    }}
                    events={loadedEvents}
                    allDaySlot={false}
                    editable={true}
                    selectable={true}         
                    dayMaxEvents={true}
                    selectOverlap={false}   
                    eventOverlap={false}
                    datesSet={changeDate}
                    eventContent={renderEventContent}                  
                    select={(selectInfo) => handleSelect(selectInfo, calendarApi, user, setSelectedData, setShowFormWeekView)}        
                    eventChange={updateChangeDatesEvent}
                    dateClick={(info) => handleMonthDayClick(info, setSelectedDate, setShowFormMonthView)}
                    eventDidMount={mountedEvent}     
                    eventClick={(event) => handleEventClick(event, setFormData, setSelectedData, setShowFormClickEvent, user, isUserManager)}         
                />    
            </section>   
            <section className='px-5 sm:px-0 flex justify-between gap-5'>
                <div>                    
                    <h4 className='text-xl text-slate-600 mb-2 mt-4'>Legenda:</h4>
                    <div className='flex gap-2'>                    
                        <FaSquare className='text-pending text-xl' />
                        <p className='text-l text-slate-600 mb-2'>Reserva Pendente</p>
                    </div>
                    <div className='flex gap-2'>                    
                        <FaSquare className='text-aproved text-xl' />
                        <p className='text-l text-slate-600 mb-2'>Reserva Aprovada</p>
                    </div>
                    <div className='flex gap-2'>                    
                        <FaSquare className='text-other text-xl' />
                        <p className='text-l text-slate-600 mb-2'>Bloco Ocupado</p>
                    </div>
                    <div className='flex gap-2'>                    
                        <FaSquare className='text-classes text-xl' />
                        <p className='text-l text-slate-600 mb-2'>Bloco de Aulas</p>
                    </div>
                </div>
                <div>
                {spaceManagers.length !== 0 &&
                    <>
                    <h4 className='text-xl text-slate-600 mb-2 mt-4'>Gestor(es) de Sala:</h4>
                    <ul className='flex flex-wrap gap-7'>  
                        {spaceManagers.map((manager) => (
                          <li key={manager._id}>
                            <span className='text-slate-800 text-lg'>{manager.name}</span><br />
                            <span className='font-bold'>Email:</span> {manager.email}<br />
                            <span className='font-bold'>Ext.:</span> {manager.phone}<br />
                            <span className='font-bold'>Gab.:</span>{manager.office}
                          </li>  
                        ))}
                    </ul>
                    </>
                }
                </div>
            </section>    
        </section>      
        {showTutorial && 
            <FormShowTutorial 
                setIsChecked={setIsChecked} 
                handleTutorial={handleTutorial} 
                animation={animation} 
                isChecked={isChecked} 
                tutorialRef={tutorialRef} 
            />}
        {showFormMonthView && 
            <FormEventClickMonth 
                selectedDate={selectedDate} 
                handleMonthSubmitReservation={()=> handleMonthSubmitReservation(selectedDate, startTime, endTime, formData, space, submitReservation, setSelectedDate, setStartTime, setEndTime, setShowFormMonthView, setFormData)} 
                startTime={startTime} 
                setStartTime={setStartTime} 
                endTime={endTime} 
                setEndTime={setEndTime} 
                formData = {formData}
                onChangeFormData={(e) => onChangeFormData(e, setFormData)} 
                handleCancelReservation={() => handleCancelReservation(setSelectedDate, setStartTime, setEndTime, setSelectedData, setFormData, setShowFormMonthView, setShowFormWeekView, setShowFormClickEvent)} 
            />}
        {showFormWeekView && 
            <FormEventClickWeek 
                selectedData={selectedData} 
                handleWeekSubmitReservation={() => handleWeekSubmitReservation(selectedData, formData, space, submitReservation, setShowFormWeekView, setSelectedData, setFormData)} 
                formData = {formData}
                onChangeFormData={(e) => onChangeFormData(e, setFormData)} 
                handleCancelReservation={() => handleCancelReservation(setSelectedDate, setStartTime, setEndTime, setSelectedData, setFormData, setShowFormMonthView, setShowFormWeekView, setShowFormClickEvent, )} 
            />}
        {showFormClickEvent && 
            <FormEventClick 
                selectedData={selectedData} 
                handleSubmitUpdateReservation = {(e) => handleSubmitUpdateReservation(e, updateEvent, formData, selectedData, setShowFormClickEvent, setSelectedData, setFormData)}
                formData = {formData}
                onChangeFormData={(e) => onChangeFormData(e, setFormData)} 
                handleCancelReservation={() => handleCancelReservation(setSelectedDate, setStartTime, setEndTime, setSelectedData, setFormData, setShowFormMonthView, setShowFormWeekView, setShowFormClickEvent)} 
                changeState ={changeState}
                deleteEvent={deleteEvent}
                isUserManager={isUserManager}
                user={user}
            />}
    </>
    )
}

export default Space
export const roleTypes = {
    employee: 'Funcionário/Docente',
    student: 'Estudante',
    manager: 'Gestor',
    admin: 'Administrador',
    root: 'Root'
}

export const spaceTypes = {
    meet: 'Reunião',
    class: 'Aula',
    aud: 'Auditório',
    lab: 'Laboratório'
}
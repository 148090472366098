//Modules
import axios from 'axios'

//Variables
const API_URL = '/api/reservations/'
const { REACT_APP_COD_ESCOLA, REACT_APP_EVENTOS_EP, REACT_APP_SUMARIOS_API, REACT_APP_APIKEY } = process.env


const getSpaceReservations = async (spaceData,userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }
    
    const response = await axios.post(`${API_URL}space/${spaceData.spaceId}`, spaceData, config)

    return response.data
}

const getSpaceClassesReservations = async (data) => {
    
   try {    
       const response = await axios.get(`${REACT_APP_SUMARIOS_API}${REACT_APP_EVENTOS_EP}${REACT_APP_COD_ESCOLA}${data.codSpace}?apikey=${REACT_APP_APIKEY}&fim=${data.fim}&inicio=${data.inicio}`)
       return response.data
   } catch (error) {
        return error
   }

}

const getUserReservations = async (userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }
    const response = await axios.get(`${API_URL}user`, config)    

    return response.data
}

const getReservations = async (userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }
    const response = await axios.get(API_URL, config)
    return response.data
}

const getSingleReservation = async (reservationID, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }
    }
    const response = await axios.get(`${API_URL}reservation/${reservationID}`, config)
    return response.data
}

const setReservation = async (reservationData, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }        
    }

    const data = {
            begin: `${reservationData.reservation.startStr}.000Z`,
            end: `${reservationData.reservation.endStr}.000Z`,
            state: 1,
            user_id: userData._id,
            space_id: reservationData.space,
            description: reservationData.reservation.description,
            obs: reservationData.reservation.obs,
        }

    const response = await axios.post(`${API_URL}`, data, config)

    return response.data
}

const updateReservation = async (reservationData, userData) => {    
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }        
    }
    const data = {
            begin: reservationData.begin,
            end: reservationData.end,
            state: reservationData.state,
            description: reservationData.description,
            obs: reservationData.obs,
        }

    const response = await axios.put(`${API_URL}${reservationData.id}`, data, config)

    return response.data
}

const deleteReservation = async (reservationID, userData) => {
    const config = {
        headers: {
            Authorization: `Bearer ${userData.token}`
        }        
    }

    const response = await axios.delete(`${API_URL}${reservationID}`, config)

    return response.data
}

const reservationService = {
    getSpaceReservations,
    getSpaceClassesReservations,
    getUserReservations,
    getReservations,
    getSingleReservation,
    setReservation,
    updateReservation,
    deleteReservation
}

export default reservationService
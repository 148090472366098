//Modules
import { formatDate } from '@fullcalendar/core'

const FormEventClick = ({ selectedData, formData, onChangeFormData, handleCancelReservation, handleSubmitUpdateReservation, changeState, deleteEvent, isUserManager, user}) => {
    const { start, end } = selectedData.event._instance.range
    const { description, obs } = formData
    const today = new Date()

  return (
    <section>
        <div className="fixed top-0 left-0 w-screen h-screen z-[999] bg-slate-600 opacity-60"></div>
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] md:w-3/4 lg:w-1/4 bg-white p-5 rounded-lg shadow-md z-[9999]">
        <h2 className='text-slate-600 mb-5 text-2xl text-center'>Reserva de {formatDate(start, {day:'2-digit', month: '2-digit', year:'numeric', timeZone: 'Europe/lisbon', locale: 'pt'})}</h2>
        <h4 className='text-slate-600 mb-5 text-xl text-center'>Das {formatDate(start, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})} às {formatDate(end, {hour:'2-digit', minute: '2-digit', timeZone: 'Europe/lisbon', locale: 'pt'})}</h4>
            <form onSubmit={handleSubmitUpdateReservation}>
                <div className="flex gap-3 items-center">
                    <label htmlFor="description" className='mb-2'>Motivo:</label>
                    <input type='text' id='description' name='description' value={description} placeholder='Motivo da reserva' onChange={onChangeFormData} className='w-full p-2 mb-3 border border-solid border-gray-400 rounded-md' />
                </div>
                <div className="flex flex-col pt-5">
                    <label htmlFor="obs" className='mb-2'>Observações:</label>
                    <textarea rows="10" cols="100" id='obs' name='obs' value={obs} placeholder='Indique as necessidades da reserva. (ex.:Copos e água, videoconferência, gravar o evento, etc...)' onChange={onChangeFormData} className='w-full p-2 mb-3 border border-solid border-gray-400 rounded-md' ></textarea>
                </div>
                <br />
                <div className='flex flex-col md:flex-row mx-5 mt-6 justify-between gap-6'>  
                    <div className='flex justify-start gap-2'>      
                        {((user.role.includes("root") && selectedData.event._def.extendedProps.state !== 3) || (selectedData.event._instance.range.start > today && isUserManager)) ? 
                        <>
                            {selectedData.event.extendedProps.state === 2 ? 
                            <button className='bg-pending text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer' type="button" onClick={() => changeState(selectedData)}>Rejeitar</button>:
                            <button className='bg-aproved text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer' type="button" onClick={() => changeState(selectedData)}>Aprovar</button>}
                        </>
                        :                     
                        <span></span>}          
                        {((user.role.includes("root") && selectedData.event._def.extendedProps.state !== 3) || (selectedData.event._instance.range.start > today && (isUserManager || selectedData.event._def.extendedProps.user === user._id))) ? 
                        <button className='bg-rejected text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer' type="button" onClick={() => deleteEvent(selectedData)}>Apagar</button>:                     
                        <span></span>}                    
                    </div>
                    <div className='flex justify-start gap-2'>
                        {(user.role.includes("root") ? true : (selectedData.event._instance.range.start > today ? (isUserManager ? true : (selectedData.event._def.extendedProps.user === user._id ? (selectedData.event._def.extendedProps.state === 1 ? true : false) : false)) : false)) ? 
                        <button className='bg-esact-600 text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer hover:bg-esact-900' type="submit">Guardar</button> :
                        <span></span>}
                        <button className='bg-esact-600 text-white border-none rounded-md px-5 py-2 text-sm cursor-pointer hover:bg-esact-900' type="button" onClick={handleCancelReservation}>Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </section>
  )
}

export default FormEventClick